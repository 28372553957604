import React from 'react'
import { BlockResultModel, TestimonialGroupsModel } from '../../../domain/models'
import { testimonialGroupsToView } from '../../../data/usecases/testimonial-groups-to-view/testimonial-groups-to-view'
import { AspectRatioBox, Box, Flex, Image, PseudoBox } from '@chakra-ui/core'
import { Typography, ButtonRedGreen } from '../../ui/foundation'
import { goToUrl } from '../../../utils/helpers/go-to-url'
import { useHistory } from 'react-router'

const TestimonialGroups = (props: BlockResultModel): JSX.Element|null => {
  const history = useHistory()
  const content: TestimonialGroupsModel|null = testimonialGroupsToView.build(props, 'php')
  const { block } = props

  if (!content) {
    return null
  }

  return (
    <Box
      background="url('/images/bg-block-testimonial.jpg') no-repeat top center"
      backgroundSize="cover"
      py={[16, 24, 32]}
      id={`block-${block.id}`}
    >
      <Box w={['90%', '', '80%']} mx="auto">
        <Box maxW={['100%', '', '600px']} mx="auto">
          <Typography type="4xl" as="h2" textAlign="center">{block.title}</Typography>
          <Typography
            type="sm"
            as={'div'}
            textAlign="center"
            mb={10}
            dangerouslySetInnerHTML={{ __html: block.description }}/>
        </Box>

        {content.testimonials.map((testimonial, index) => (
          <Flex
            key={testimonial['@id']}
            w="100%"
            mb={[12, 24]}
            alignItems="center"
            flexDir={index % 2 === 0 ? 'row' : 'row-reverse'}
            justifyContent="space-between"
          >
            <Box w={['60%', '45%']}>
              <PseudoBox
                transition="transform .3s ease"
                _hover={{
                  transform: 'scale(1.05)'
                }}
              >
                {testimonial.description && (
                  <Typography
                    type="2xl"
                    fontSize={['md', '2xl']}
                    as="div"
                    fontWeight="bold"
                    dangerouslySetInnerHTML={{ __html: testimonial.description }}
                  />
                )}
                <Typography type="md" as="h3" mb={2} fontWeight="bold">{testimonial.author}</Typography>
                <Typography type="xs" as="p" >{testimonial.companyJobRole}</Typography>
              </PseudoBox>
            </Box>
            <Box w={['35%', '50%']}>
              {testimonial.avatar && (
                <AspectRatioBox w="100%" maxW="370px" ratio={ 9 / 12} ml={index % 2 === 0 ? '0' : 'auto'}>
                  <Image src={testimonial.avatar} ignoreFallback alt={testimonial.author} objectFit="cover" />
                </AspectRatioBox>
              )}
            </Box>
          </Flex>
        ))}
      </Box>
    </Box>
  )
}

export default TestimonialGroups
