import React from 'react'
import { Link } from 'react-router-dom'
import styled from '@emotion/styled'
import { Box, BoxProps } from '@chakra-ui/core'

const NavFooter: React.FC = () => {
  return (
    <CustomBox justifyContent={['center', '', 'flex-start']}>
      <Link to="/quem-somos">
        Quem somos
      </Link>
      <Link to="/contato">
        Contato
      </Link>
    </CustomBox>
  )
}

const CustomBox = styled(Box)<BoxProps>`
  display: flex;
  a {
    padding: 0 10px;
    border-right: 1px solid #fff;
    line-height: 15px;
    opacity: 0.5;
    transition: opacity .2s ease-in-out;
    &:hover {
      opacity: 1;
    }
    &:last-child {
      padding-right: 0;
      border-right: 0;
    }
  }
`

export default NavFooter
