import { CurrentPlanModel } from '../../../../domain/models'
import { AxiosResponse } from 'axios'
import { api } from '../../../../infra/http/remote'

export const makeCurrentPlan = async (): Promise<CurrentPlanModel[]> => {
  try {
    const response: AxiosResponse = await api.get('/current_customer_subscriptions')

    return response.data['hydra:member']
  } catch (e) {
    return []
  }
}

export const makeCancelPlan = async (id: number): Promise<string> => {
  try {
    const response: AxiosResponse = await api.post(`current_customer_subscriptions/${id}/cancel`, {
      cancellationReason: `Solicitou cancelamento de assinatura para o plano #${id}`
    })

    return response.data.message
  } catch (e) {
    return e.response.data['hydra:description'] || 'Erro ao cancelar o plano, entre em contato conosco'
  }
}
