import Cookie from 'js-cookie'
import { settings } from '../../config/settings'

export const clearStorage = (): void => {
  localStorage.clear()
  sessionStorage.clear()

  Object.keys(Cookie.get()).forEach((item) => {
    if (item !== 'agreeUseCookie') {
      Cookie.remove(item)
    }
  })

  Cookie.remove(settings.TOKEN, { domain: settings.DOMAIN })
}
