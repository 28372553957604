import React, { useState } from 'react'
import { Avatar, Box, Flex } from '@chakra-ui/core'
import { Container, Typography } from '../../ui/foundation'
import styled from '@emotion/styled/macro'
import { Link } from 'react-router-dom'
import customTheme from '../../../styles/theme'

import './styles/circle.css'
import { BlockResultModel } from '../../../domain/models'
import {
  changeWeekDayToIndexNumber,
  secondsToHour
} from '../../../utils/helpers/date'
import { getFirstName, getUser } from '../../../utils/helpers/user'
import { PseudoBoxCustom } from '../../ui/layout'

const Dashboards = ({ content }: BlockResultModel): JSX.Element|null => {
  const user = getUser()
  const data = content['hydra:member'] || null
  const now = new Date()
  const [tab, setTab] = useState<number>(0)

  if (!data) {
    return null
  }

  const myTraining = data[0]
  const myWeek = data[1]
  const myEvolution = data[2].dashboards
  const myContent = data[3]
  const myChangeMyTraining = data[4]

  const myTrainingPercent = (total: number, todo: number): string => {
    const calc = 100 - ((todo * 100) / total)
    if (!isNaN(calc)) {
      return calc.toFixed(0)
    }

    return '0'
  }

  const calcChart = (cur: number): number => {
    const bigValue = Math.max(...myEvolution[tab].series)
    const calc = (cur * 100) / bigValue
    if (!isNaN(calc)) {
      return parseFloat(calc.toFixed(2))
    }
    return 0
  }

  const calcChartUser = (total: number, totalUser: number, decimals?: number): number => {
    const calc = (totalUser * 100) / total
    if (!isNaN(calc)) {
      return parseFloat(calc.toFixed(decimals === 0 ? 0 : (decimals || 2)))
    }
    return 0
  }

  const currentTab = (idx: number): void => {
    if (tab !== idx) {
      setTab(idx)
    }
  }

  const getColorsTraining = (idx: number): string => {
    switch (idx) {
      case 0: return 'white'
      case 1: return 'green.700'
      case 2: return 'pink.200'
    }
  }

  const myWeekStatus = (serie: boolean, idx: number): string => {
    const day = now.getDay()
    const weekday = changeWeekDayToIndexNumber(day)
    const classes = []
    if (idx === weekday) { classes.push('active') }
    if (serie && idx <= weekday) { classes.push('success') }
    if (!serie && idx <= weekday) { classes.push('fail') }

    return classes.join(' ')
  }

  return (
    <PseudoBoxCustom
      bg="gray.700"
      color="white"
      py={16}
      pos="relative"
      _after={{
        content: '""',
        position: 'absolute',
        left: 0,
        right: 0,
        bg: '#ffffff',
        bottom: 0,
        height: '35px',
        clipPath: 'polygon(100% 0, 0% 100%, 100% 100%)'
      }}
    >
      <Container>
        <CustomFlex flexWrap="wrap" h="100%" justifyContent="space-between">
          <Box w={['100%', '100%', '25%']}>
            <Avatar src={user.user.avatar} size={'xl'} mb={4} />
            <Typography type="2xl" as="h1" mb={4}>{getFirstName(user.user.name)} tá no Gaiz!</Typography>
            <Typography mb={4} fontSize="sm">
              <p>Então é hora de se exercitar!</p>
              <p>Acompanhe sua evolução e veja qual é a sua próxima aula.</p>
            </Typography>
            {myContent.series[0] && (
              <Link to={`/treino/${myContent.secondarySeries[0]}/${myContent.series[0]}`} className="next-lesson">Começar próxima aula</Link>
            )}
          </Box>

          <Box w={['100%', '100%', '30%']}>
            <Typography type="xl" as="h2" mb={8}>Meu treino</Typography>

            <Flex>
              <Flex flexDir="column" justifyContent="space-between" w={['50%']}>

                <div className={`c100 p${myTrainingPercent(myTraining.series[0], myTraining.series[2])}`}>
                  <span>{myTrainingPercent(myTraining.series[0], myTraining.series[2])}%</span>
                  <div className="slice">
                    <div className="bar" />
                    <div className="fill" />
                  </div>
                </div>

                {myChangeMyTraining.series[0] && (
                  <Link to="/dashboard" className="update-lesson">
                    Alterar<br/>
                    Meu Treino
                  </Link>
                )}

              </Flex>
              <Box w={['50%']} textAlign="center">
                {myTraining.labels.map((label, idx) => (
                  <Box
                    key={`my-training-${idx}`}
                    mb={myTraining.labels.length === (idx + 1) ? 0 : 4}
                    color={getColorsTraining(idx)}
                  >
                    <Typography type="3xl" as="h3" mb={2}>{secondsToHour(myTraining.series[idx])}</Typography>
                    <Typography type="xs" fontWeight="bold">{label}</Typography>
                  </Box>
                ))}
              </Box>
            </Flex>
          </Box>

          <Box w={['100%', '100%', '45%']} className="last">
            <Box py={4} borderBottom="1px solid #777">
              <Typography type="xl" as="h2" mb={8}>Minha Semana</Typography>
              <Flex>
                {myWeek.labels.map((item, idx) => (
                  <BoxDay key={`my-week-${idx}`} className={myWeekStatus(myWeek.series[idx], idx)}>
                    <p>{item}</p>
                    <div>{myWeek.secondaryLabels[idx]}</div>
                  </BoxDay>
                ))}
              </Flex>
            </Box>
            <Box py={4}>
              <Typography type="xl" as="h2" mb={8}>Minha Evolução</Typography>
              <Box>
                <Tabs>
                  {myEvolution.map((dash, idx) => (
                    <span key={`dash-${idx}`} className={idx === tab ? 'active' : ''} onClick={() => currentTab(idx)}>
                      {dash.id}
                    </span>
                  ))}
                </Tabs>
                <Flex justifyContent="space-between">
                  {myEvolution[tab].labels.map((item, idx) => (
                    <CharVerticalBar
                      key={`dash-tab-${idx}`}
                      currentSerie={calcChart(myEvolution[tab].series[idx])}
                      currentSerieUser={calcChartUser(myEvolution[tab].series[idx], myEvolution[tab].secondarySeries[idx])}
                    >
                      {/* <p className={myEvolution[tab].secondarySeries[idx] === myEvolution[idx] ? 'active' : ''}>{myEvolution[tab].secondarySeries[idx]}h</p> */}
                      <p>{calcChartUser(myEvolution[tab].series[idx], myEvolution[tab].secondarySeries[idx], 0)}%</p>
                      <div className="total" />
                      <p>{item}</p>
                      <BoxHover>
                        <p>Total: {secondsToHour(myEvolution[tab].series[idx])}</p>
                        <p>Concluídas: {secondsToHour(myEvolution[tab].secondarySeries[idx])}</p>
                      </BoxHover>
                    </CharVerticalBar>
                  ))}
                </Flex>
              </Box>
            </Box>
          </Box>
        </CustomFlex>
      </Container>
    </PseudoBoxCustom>
  )
}

export default Dashboards

const BoxHover = styled.div`
  background-color: rgba(0, 0, 0, 0.8);
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -70px;
  width: 160px;
  font-size: 0.75rem;
  border-radius: 4px;
  padding: 5px;
  display: none;
  z-index: 2;
`

const BoxDay = styled.div`
  margin: 0 3px 10px;
  text-align: center;
  font-size: 14px;
  > div {
    width: 35px;
    height: 35px;
    text-align: center;
    background: #868d97;
    border: 2px solid #868d97;
    border-radius: 100%;
    line-height: 30px;
    font-weight: bold;
  }
  &.success{
    > div {
      background-color: ${customTheme.colors.green['700']};
      border-color: ${customTheme.colors.green['700']};
    }
  }
  &.fail{
    > div {
      background-color: ${customTheme.colors.pink['200']};
      border-color: ${customTheme.colors.pink['200']};
    }
  }
  &.active{
    > div {
      border-color: #fff;  
    }
  }
`

const Tabs = styled(Box)`
  span {
    cursor: pointer;
    color: #777777;
    font-weight: bold;
    display: inline-block;
    margin-right: 5px;
    &.active {
      color: #ffffff;
    }
  }
`

type CharVerticalBarProps = {
  currentSerie: number
  currentSerieUser: number
}

const CharVerticalBar = styled.div<CharVerticalBarProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 160px;
  align-items: center;
  justify-content: flex-end;
  font-size: 13px;

  &:hover ${BoxHover} {
      display: block;
  }
  p {
    font-weight: bold;
    &.active{
      color: ${customTheme.colors.green['700']};
    }
  }
  
  .total {
    position: relative;
    background: #bec8d2;
    width: 18px;
    border-radius: 4px;
    height: ${({ currentSerie }) => `${currentSerie}px`};
    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      border-radius: 4px;
      background: ${customTheme.colors.green['700']};
      height: ${({ currentSerieUser }) => `${currentSerieUser}%`};
    }
  }
`

const CustomFlex = styled(Flex)`
  margin: 0 -30px;
  > div {
    padding: 0 30px;
    border-right: 1px solid #777;
    display: flex;
    flex-direction: column;
    justify-content: center;
    &.last {
      border-right: 0;    
    }  
  }
  p {
    margin-bottom: 2px;
  }
  .next-lesson {
    color: ${customTheme.colors.green['700']}; 
    display: inline-block;
    font-weight: bold;
  }
  .update-lesson{
    display: inline-block;
    font-weight: bold;
    color: #a5a0a0;
  }
`
