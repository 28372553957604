import { css } from '@emotion/core'

export const globalStyles = css`
  @font-face: {
    font-family: 'Biennale';
    src: url('../assets/fonts/biennale/biennale-regular.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
  }
  
  @font-face: {
    font-family: 'Biennale';
    src: url('../assets/fonts/biennale/biennale-bold.woff2') format('woff2');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
  }
  
  @font-face: {
    font-family: 'Biennale';
    src: url('../assets/fonts/biennale/biennale-thin.woff2') format('woff2');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
  }
  
  html {
    scroll-behavior: smooth;
  }
  
  * {
    outline: none;
  }
  
  body, input, textarea {
    font-family: 'Open Sans', sans-serif;
  }
  
  h1, h2, h3, h4, h5, h6 {
    font-family: 'Biennale', sans-serif;
  }
  
  p {
    margin-bottom: 10px;
  }
  
  img {
    width: unset!important;
  }  
  .embed-responsive {
    position: relative;
    &::before {
      height: 0;
      content: "";
      display: block;
      padding-bottom: 56.25%;
    }
    iframe {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }
  }

  #my-webexteams-widget {
    position: absolute;
    left: 0;
    bottom: 0;
    top: 0;
    right: 0;
  }

  .blocked {
    cursor: no-drop;
    pointer-events: none;
  }
`
