import { CurrentPlanModel } from '../../../../domain/models'
import { Box, Flex } from '@chakra-ui/core'
import {
  ButtonRedGreen,
  ButtonRedOutlineGreen,
  Typography
} from '../../foundation'
import React from 'react'
import { enumFrequency } from '../../../../utils/enums/frequency'
import { enumStatus } from '../../../../utils/enums/status'
import { useModal } from '..'
import { makeCancelPlan } from '../../../../main/factories/usecases/subscriptions/current-plans'
import { useAlertBox } from '../alert-box/alert-box-context'

interface CardMyPlanItemProps {
  plan: CurrentPlanModel
  handleReloadPage: () => void
}
export const CardMyPlanItem = ({ plan, handleReloadPage }: CardMyPlanItemProps): JSX.Element => {
  const { alertBox, setOpen, setContent } = useModal()
  const alertContext = useAlertBox()

  const handleClose = () => {
    setOpen(false)
    setContent(null)
  }

  const handleCancelPlan = async () => {
    handleClose()
    const data = await makeCancelPlan(plan.id)
    handleReloadPage()
    alertContext.alertBox(data)
  }

  const handleClick = () => {
    alertBox(() => (
      <Box px={8} py={16} textAlign="center">
        <Typography type="lg" mb={8}>Você tem certeza que deseja cancelar o plano <b>{plan.planName}</b></Typography>
        <Flex justifyContent="center">
          <ButtonRedOutlineGreen type="submit" mr={4} onClick={handleClose}>NÃO</ButtonRedOutlineGreen>
          <ButtonRedGreen type="submit" onClick={handleCancelPlan}>SIM</ButtonRedGreen>
        </Flex>
      </Box>
    ))
  }

  return (
    <Box
      border="1px solid #313131"
      borderRadius="10px"
      p={8}
    >
      <Typography type="2xl" as="h2" isTruncated>Assinatura: {plan.planName}</Typography>
      <p>Status: <b>{enumStatus(plan.status)}</b></p>
      <p>Periodicidade: <b>{enumFrequency(plan.frequency)}</b></p>
      {plan.status !== 'canceled' && (
        <ButtonRedOutlineGreen type="submit" onClick={handleClick}>
          Cancelar
        </ButtonRedOutlineGreen>
      )}
    </Box>
  )
}
