import React from 'react'
import { Typography } from '../ui/foundation'
import { Avatar, Box, Flex, PseudoBox } from '@chakra-ui/core'
import { CommentPost } from './index'
import { CommentModel } from '../../domain/models'
import { getUser } from '../../utils/helpers/user'
import { useComment } from './contexts/comment'
import { api } from '../../infra/http/remote'
import { AlertBoxContextInterface, useAlertBox } from '../ui/layout/alert-box/alert-box-context'

interface CommentsProps {
  contentUri: string
  comments: CommentModel[]
}

const Comments = ({ contentUri, comments }: CommentsProps) => {
  const { alertBox }: AlertBoxContextInterface = useAlertBox()
  const user = getUser()
  const { removeComment } = useComment()

  const handleRemoveComment = async (idComment: number) => {
    try {
      await api.delete(`/comments/${idComment}`)
      removeComment(idComment)
      alertBox('Comentário excluído com sucesso')
    } catch (e) {
      alertBox('Não foi possível excluir seu comentário')
    }
  }

  return (
    <Box mt={16}>
      <Typography type="3xl" as="h3" color="purple.500">COMENTÁRIOS</Typography>

      <CommentPost contentUri={contentUri} />

      {comments.map((comment, index) => (
        <Flex
          key={comment['@id']}
          my={6}
          bg={index % 2 === 0 ? '#eee' : '#f9f9f9'}
          p={4}
          borderRadius="4px"
          pos="relative"
        >
          <Avatar src={comment.user.avatar} title={comment.user.name} />
          <Box w="100%" ml="10px">
            <Typography type="xs" mb={0}><b>{comment.user.name}</b></Typography>
            {comment.text}

            {comment.user.id === user.user.id && (
              <PseudoBox
                mt={4}
                fontSize="xs"
                color="#999"
                cursor="pointer"
                onClick={() => handleRemoveComment(comment.id)}
                _hover={{
                  color: '#313131'
                }}
              >Excluir</PseudoBox>
            )}
          </Box>
        </Flex>
      ))}

    </Box>
  )
}

export default Comments
