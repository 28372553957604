import React, { useEffect, useState } from 'react'
import { Box, Grid } from '@chakra-ui/core'
import { Typography } from '../../component/ui/foundation'
// import { getTrialsUntil } from '../../utils/helpers/date'
import { CardPlanItem, StepsDots } from '../../component/ui/layout'
import { PlanDetailModel } from '../../domain/models'
import Loading from '../../component/loading/loading'
import { Redirect } from 'react-router'
import { makePlan, verifyCoupon } from '../../main/factories/usecases/subscriptions/make-plans'
import { getStorePlanSession } from '../../utils/helpers/store-plan-session'
import { PlanItem } from '../../component/plans/plan-item'
import { applyDiscount, priceFormat } from '../../utils/helpers/price'
import PaymentMethods from '../../component/payment/payment-methods'
import { removeLocalStorage } from '../../infra/storage/localStorage'
import { CouponContext } from '../../component/payment/coupon-context'

const Payment = () => {
  const [loading, setLoading] = useState<boolean>(true)
  const [data, setData] = useState<PlanDetailModel>(null)
  const [totalPrice, setTotalPrice] = useState<number>(0)
  const [coupon, setCoupon] = useState<string>(null)
  const [couponActive, setCouponActive] = useState<boolean>(false)
  const [couponDetails, setCouponDetails] = useState<any>(null)

  useEffect(() => {
    (async () => {
      try {
        const plan = getStorePlanSession()
        const hasPlanItem = ('planItems' in plan)
        const id = parseInt(plan.plan.replace('/v1/plans/', ''), 10)
        const content = await makePlan(id)
        removeLocalStorage('coupon')

        setData(() => ({
          ...content,
          planItems: content.planItems.filter(item => {
            if (hasPlanItem) {
              return item.showPlanItem && plan.planItems.includes(item['@id'])
            }
            return item.showPlanItem
          })
        }))

        setTotalPrice(() => {
          let total = 0
          if (hasPlanItem) {
            content.planItems.filter((item) => plan.planItems.includes(item['@id'])).forEach(item => {
              total += item.price
            })
            return total
          }

          return content.totalPrice
        })
        setLoading(false)
      } catch (e) {
        setData(null)
        setLoading(false)
      }
    })()
  }, [])

  const handleCoupon = async () => {
    setCouponDetails(await verifyCoupon(coupon, data['@id']))
    setCouponActive(true)
  }

  if (loading) {
    return <Loading/>
  }

  if (!data) {
    return <Redirect to="/escolha-seu-plano" />
  }

  return (
    <Box py={8}>

      <StepsDots currentDot={3} />

      <Box textAlign="center" w={['100%', '', '50%']} mx="auto" mb={12}>
        <Typography type="4xl" as="h1" mb={4}>ADICIONE UM MÉTODO DE PAGAMENTO</Typography>
        {/* <Typography type="sm" as="p" mb={8}>
          Não se preocupe, você não está pagando nada hoje.
          Em {getTrialsUntil(7)}, a forma de pagamento que você adicionar será cobrada.
        </Typography> */}

        <Typography type="md" as="h2" mb={8}>Planos escolhidos</Typography>
      </Box>

      <Box w={['100%', '70%', '33.33333%', '25%']} mx="auto" mb={12}>
        <CardPlanItem dark data={{
          title: data.name,
          description: data.description,
          image: data.image,
          selectable: true,
          totalPrice: data.totalPrice,
          url: '/pagamento'
        }} />
      </Box>

      <Box w={['100%', '', '70%']} mx="auto" mb={12}>
        <Grid templateColumns={['1fr', 'repeat(2, 1fr)', 'repeat(3, 1fr)']} gap={5} mb={16}>
          {data.planItems.map(item => (
            <PlanItem key={item['@id']} item={item} selectable={false} />
          ))}
        </Grid>

        <Typography
          type={couponActive && couponDetails ? 'xl' : '2xl'}
          mb={4}
          textDecoration={couponActive && couponDetails ? 'line-through' : 'none'}
        >
          TOTAL A SER PAGO: <strong>{priceFormat(totalPrice)}</strong>
        </Typography>
        {couponActive && couponDetails && (
          <>
            <Typography type="xl" mb={4}>
              DESCONTO APLICADO: <strong>{priceFormat(applyDiscount(totalPrice, couponDetails))}</strong>
            </Typography>
            <Typography type="2xl" mb={4}>
              TOTAL A SER PAGO: <strong>{priceFormat(totalPrice - applyDiscount(totalPrice, couponDetails))}</strong>
            </Typography>
          </>
        )}

        <CouponContext.Provider value={{ setCoupon, coupon, handleCoupon, couponActive, couponDetails }}>
          <PaymentMethods
            hasCoupon
            installments={data.installments}
            currentPrice={totalPrice - applyDiscount(totalPrice, couponDetails)}
          />
        </CouponContext.Provider>
      </Box>

    </Box>
  )
}

export default Payment
