import React from 'react'
import { Box, Image } from '@chakra-ui/core'
import { Footer } from './index'
import { Container } from '../ui/foundation'
import styled from '@emotion/styled'
import { Link } from 'react-router-dom'
import customTheme from '../../styles/theme'

import Logo from '../../assets/images/logo-white.png'

const BaseSignIn: React.FC = ({ children }) => {
  return (
    <>
      <CustomHeader>
        <Link to="/">
          <Image cursor="pointer" src={Logo} mx="auto"/>
        </Link>
      </CustomHeader>
      <Container>
        <Box width={['90%', '70%', '50%', '35%']} mx="auto" py={32}>
          {children}
        </Box>
      </Container>
      <Footer />
    </>
  )
}

const CustomHeader = styled.div`
  background-color: ${customTheme.colors.green['700']};
  padding: 15px 0;
  text-align: center;
`

export default BaseSignIn
