import React from 'react'
import { AspectRatioBox, Avatar, Box, Flex, Image } from '@chakra-ui/core'
import { Typography } from '../../foundation'
import styled from '@emotion/styled'
import { ContentListModel } from '../../../../domain/models'
import { ReactComponent as Lock } from '../../icons/lock.svg'
import { ReactComponent as Calendar } from '../../icons/calendar-week-regular.svg'
import { Link } from 'react-router-dom'

interface CardFolderContentItem {
  trainingSlug: string
  content: ContentListModel
}

const CardFolderContentItem: React.FC<CardFolderContentItem> = ({ content, trainingSlug }) => {
  return (
    <Link
      className={content.status === 'published' ? '' : 'blocked'}
      to={content.status === 'published' ? `/treino/${trainingSlug}/${content.weekDayEn.toLowerCase()}` : '#'}>
      <CustomItem borderRadius="sm">
        <AspectRatioBox ratio={8 / 12} borderRadius="md">
          {content.thumb ? (
            <Image
              objectFit="cover"
              src={content.thumb}
              alt={content.weekDay}
              ignoreFallback
            />
          ) : <div/>}
        </AspectRatioBox>
        <MetaInfo>
          {content.author && (
            <Flex alignItems="center">
              {content.author.avatar && (
                <Avatar size="sm" mr={2} name={content.author.name} src={content.author.avatar} />
              )}
              Por: {content.author.name}
            </Flex>
          )}

          {content.status !== 'published' && (
            <Icon><Lock /></Icon>
          )}

          <Typography type="2xl" as="h3" mb={1} isTruncated>
            <IconText>
              <Calendar /> {content.weekDay.toUpperCase()}
            </IconText>
          </Typography>
        </MetaInfo>
      </CustomItem>
    </Link>
  )
}

const CustomItem = styled(Box)`
  width: 100%;
  position: relative;
  overflow: hidden;
  box-shadow: 0 3px 12px rgba(0, 0, 0, 0.53);
  transition: transform 0.3s ease-in-out;
  &:hover {
    transform: translateY(-5px);
  }
`

const Icon = styled.div`
  color: #ffffff;
  svg {
    width: 50px;
    margin: auto;
  }
`

const IconText = styled.div`
  color: #ffffff;
  display: flex;
  svg {
    width: 30px;
    margin-right: 10px;
  }
`

const MetaInfo = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: #fff;
  background: rgba(0, 0, 0, .30);
`

export default CardFolderContentItem
