import React from 'react'
import { I18n } from 'aws-amplify'
import { Box, Flex, Grid } from '@chakra-ui/core'
import { ButtonRedGreen, Container } from '../../component/ui/foundation'
import NavProfile from '../../component/nav-profile/nav-profile'
import { getUser } from '../../utils/helpers/user'
import AvatarUpload from '../../component/avatar-upload/avatar-upload'
import { Form, Formik } from 'formik'
import { Input } from '../../component/form-elements'
import * as Yup from 'yup'
import { useAlertBox } from '../../component/ui/layout/alert-box/alert-box-context'
import { updateUserDatabase } from '../../main/factories/usecases/user/user'
import InputPhoneMasked from '../../component/form-elements/phone-mask'
import { ddiWithMasks } from '../../utils/enums/ddi-masks'

const Profile = () => {
  const { alertBox } = useAlertBox()
  const user = getUser()

  const getCodePhone = (phone) => {
    let code = 55
    Object.keys(ddiWithMasks).forEach((item) => {
      phone = phone || ''
      const newPhone = phone.replace(/\D/, '')
      const ddi = newPhone.slice(0, item.length)
      if (ddi === item) {
        code = ddi
      }
    })
    return code
  }

  const getPhoneWithoutCode = (phone) => {
    phone = phone || ''
    const newPhone = phone.replace(/\D/, '')
    if (!newPhone) {
      return ''
    }
    const code = getCodePhone(newPhone).toString()

    return newPhone.slice(code.length)
  }

  const initialValues = {
    name: user.user.name || '',
    code: getCodePhone(user.profile.phoneNumber) || '55',
    phone: getPhoneWithoutCode(user.profile.phoneNumber) || '',
    email: user.user.email || ''
  }

  const onSubmit = async (values) => {
    try {
      Object.assign(values, {
        phone: `${values.code.replace(/\\D/g, '')}${values.phone.replace(/\\D/g, '')}`
      })
      await updateUserDatabase(values, user)
    } catch (e) {
      alertBox('Erro ao salvar os dados, tente mais tarde')
    }
  }

  return (
    <Box py={8}>
      <Container>
        <Grid templateColumns={['1fr', '1fr', '1fr 4fr']} gap={5} my={10}>
          <Box>
            <NavProfile urlActive={'/perfil'} />
          </Box>
          <Box>
            <Flex flexWrap="wrap">
              <Box w={['100%', '100%', '20%']}>
                <AvatarUpload user={user}/>
              </Box>
              <Box w={['100%', '100%', '40%']}>
                <Formik
                  initialValues={initialValues}
                  validationSchema={
                    Yup.object().shape({
                      name: Yup.string().required(I18n.get('ValidateRequiredField')),
                      phone: Yup.string().required(I18n.get('ValidateRequiredField')),
                      email: Yup.string().email(I18n.get('ValidateInvalidEmail')).required(I18n.get('ValidateRequiredField'))
                    })
                  }
                  onSubmit={onSubmit}
                >
                  {(actions) => (
                    <Form>
                      <Input title="Nome" name="name" defaultValue={initialValues.name} {...actions} />
                      <InputPhoneMasked
                        title="Celular"
                        name="phone"
                        nameCode="code"
                        {...actions}
                        defaultCode={initialValues.code.toString()}
                        defaultValue={initialValues.phone} />
                      <Input disabled={true} title="Email" name="email" defaultValue={initialValues.email} {...actions} />

                      <ButtonRedGreen
                        type="submit"
                        isLoading={actions.isSubmitting}
                        loadingText="Registrando"
                        isDisabled={actions.isSubmitting}
                      >
                        SALVAR
                      </ButtonRedGreen>
                    </Form>
                  )}
                </Formik>
              </Box>
            </Flex>
          </Box>
        </Grid>
      </Container>
    </Box>
  )
}

export default Profile
