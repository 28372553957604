import { createContext, useContext } from 'react'
import { CommentModel } from '../../../domain/models'

interface CommentContextInterface {
  addComment: (comment: CommentModel) => void
  removeComment: (idComment: number) => void
}
export const CommentContext = createContext<CommentContextInterface>(null)

export const useComment = () => {
  const context = useContext<CommentContextInterface>(CommentContext)

  if (!context) {
    throw new Error('CommentContext.Provider it is necessary for operation')
  }

  return {
    ...context
  }
}
