import React from 'react'
import { Link } from 'react-router-dom'
import styled from '@emotion/styled'

interface NavProfileProps {
  urlActive: string
}

const NavProfile = ({ urlActive }: NavProfileProps) => {
  const menus = [
    { title: 'Dados', url: '/perfil' },
    // { title: 'Planos', url: '/meus-planos' },
    { title: 'Alterar Senha', url: '/alterar-senha' },
    // { title: 'Alterar Cartão', url: '/alterar-cartao' },
    { title: 'Sessões do Usuário', url: '/sessoes' }
  ]

  return (
    <CustomBox>
      {menus.map((menu) => (
        <Link key={menu.url} to={menu.url} className={menu.url === urlActive ? 'active' : ''}>
          {menu.title}
        </Link>
      ))}
    </CustomBox>
  )
}

export default NavProfile

const CustomBox = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  box-shadow: 0 5px 15px rgb(0 0 0 / 0.25);
  border-radius: 4px;
  a {
    padding: 10px 0;
    margin-bottom: 10px;
    &.active {
      border-bottom: 1px solid;
    }
  }
`
