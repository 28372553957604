import { api } from '../../../../infra/http/remote'
import { ProfileModel } from '../../../../domain/models'
import { getUser } from '../../../../utils/helpers/user'

export const updateUserDatabase = async (data: any, currentUser: ProfileModel) => {
  try {
    return await api.put(currentUser.user['@id'].replace('/v1/', '/'), {
      name: data.name,
      // email: data.email,
      phoneNumber: data.phone.replace(/\D/g, '')
    })
  } catch (e) {
    return e
  }
}

type ChangePassword = {
  plainPassword: string
}
export const changePassword = async (data: ChangePassword) => {
  const user = getUser()
  return await api.post(`${user.user['@id'].replace('/v1/', '/')}/change_password`, data)
}

export const userSessions = async () => {
  try {
    const response = await api.get('/current_user_sessions')
    return response.data['hydra:member'] || []
  } catch (e) {
    return []
  }
}

export const removeUserSession = async (id) => {
  return await api.delete(`/user_sessions/${id}`)
}

export const removeUserSessions = async () => {
  return await api.delete('/current_user_sessions')
}

export const resetSessions = async () => {
  try {
    await removeUserSessions()
  } catch (e) {
    window.location.href = '/403'
  }
}
