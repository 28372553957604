export const settings = {
  siteName: 'Gaiz',
  dashboardRoute: '/dashboard',
  API_URL: process.env.REACT_APP_API_URL,
  ADMIN_URL: process.env.REACT_APP_ADMIN_URL,
  TOKEN: process.env.REACT_APP_TOKEN,
  DOMAIN: process.env.REACT_APP_DOMAIN,
  anamnesis_1: '/v1/anamnesis_forms/1',
  anamnesis_1_form: 'vamos-comecar',
  anamnesis_2: '/v1/anamnesis_forms/2',
  anamnesis_2_form: 'formulario-de-pontidao-de-atividade-fisica',
  anamnesis_steps: {
    'formulario-de-pontidao-de-atividade-fisica': 4
  }
}
